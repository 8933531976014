.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active {
  cursor: pointer !important;
  text-decoration: bold;
  color: rgb(134, 134, 204);
}

.inactive {
  cursor: pointer !important;
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.active:hover {
  cursor: pointer !important;
  color: rgb(134, 134, 204);
}

.inactive:hover {
  cursor: pointer !important;
  color: rgb(134, 134, 204);
}

.imagepage {
  color: rgb(255, 255, 255);
  text-decoration: none
}

a {
  color: rgb(0, 0, 0);
  text-decoration: none
}
a:hover {
   color: rgb(134, 134, 204)
}
.legend {
  font-size: 0.8rem !important;
  opacity: 0.6 !important;
  font-family: "Montserrat";
  font-weight: bold !important;
}

.linkHover
{
    opacity: 0.7;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    cursor: pointer;
}

.linkHover:hover
{
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.hoverHighlight:hover
{
    opacity: 1;
    background-color: rgb(255, 255, 255);

    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.modal-header{
  border-bottom: 0px
}

.iiz__img{
  height: 70vh !important;
  object-fit: contain !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  display: inline-flex !important;
  margin: 0; /* Remove margins */
}

li{
  font-size: 0.8em;
  padding: 5px;
  margin: 5px;
  border-radius:5px;
}

.available{
  font-size: 1em;
}

.unavailable{
  font-size: 1em;
}

li.slick-active{
  background-color:rgb(40, 40, 49);
}


#photos {
  /* Prevent vertical gaps */

  line-height: 0;
  -webkit-column-count: 3;
  -webkit-column-gap:   20px;
  -moz-column-count:    3;
  -moz-column-gap:      20px;
  column-count:         3;
  column-gap:           20px;  
}

#photos .imageCard {
  /* Just in case there are inline attributes */
  display: inline-block;
  width: 100% !important;

}


@media (max-width: 1000px) {
  #photos {
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;
  }
}
@media (max-width: 600px) {
  #photos {
  -moz-column-count:    1;
  -webkit-column-count: 1;
  column-count:         1;
  }
}

@media (min-width: 480px) {
  .mobileButton {
    display: none;
  }
}

body{
  margin:0;
  background-color:rgb(4,5,6)
}